@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

::-webkit-scrollbar {
  // display: none;
}

html {
  // scrollbar-width: none;
  overflow-x: hidden;
}

.wrap_inner_cantainer {
  // height: 100vh;
  overflow-x: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.header_section {
  background-color: #fff;
}

.frame_header_block {
  display: flex;
}

.frame_header_block:after,
.frame_header_block:before {
  height: 100%;
  position: absolute;
  background-repeat: repeat-y;
  top: 0;
  background-size: 100% auto;
  z-index: -1;
  width: 132px;
}

.frame_header_block:after {
  content: "";
  background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/frame/Left_Frame.png);
  left: 0;
}

.frame_header_block:before {
  content: "";
  background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/frame/Right_Frame.png);
  right: 16px;
}

img.top_frame_img {
  width: 100%;
  height: auto;
}

.top_header_logo_column.top_header_col {
  margin: auto auto auto 0;
}

/* nav menu */
.main_body_section {
  width: 86%;
  margin: auto;
}

.top_header_row {
  display: flex;
}

.top_header_col {
  margin: auto;
}

ul.ul_nav_menu {
  display: flex;
  padding: 0;
}

li.li_nav_menu_name {
  display: table-cell;
  margin: auto;
  background-color: #fff;
  padding: 16px 15px;
  margin: 0 5px;
  border-radius: 24.5px;
  width: 140px;
  text-align: center;
  box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 5%);
  transition: 0.5s;
}

.top_header_nav_column.top_header_col {
  background-color: #eefbff;
  padding: 10px 30px;
  border-radius: 0 0 43px 43px;
  display: flex;
  width: 63%;
  margin: auto;
}

li.li_nav_menu_name a {
  color: #000;
  font-size: 16px;
  text-decoration: none;
  opacity: 57%;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: 0.5s;
}

.top_header_nav_block {
  margin: auto;
}

li.li_nav_menu_name:hover {
  box-shadow: 0px 6px 9.7px 0.3px rgb(27 75 83 / 12%),
    inset 0 -6px 10px #0000001f;
  background-color: rgb(254, 199, 69);
  transition: 0.5s;
}

li.li_nav_menu_name:hover a {
  color: #fff;
  transition: 0.5s;
  opacity: 1;
}

/* left side btn */
.left_btn_icon_block {
  position: relative;
}

.left_btn_icon_block:after {
  z-index: -1;
  content: "";
  background-image: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/logout_img.png);
  width: 114px;
  height: 100px;
  position: absolute;
  top: -19px;
  left: -21px;
  background-repeat: no-repeat;
}

.top_header_btn_column.top_header_col {
  margin: auto 0 auto auto;
}

/* BOTTOM SECTION */
img.bottom_frame_img {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 121px;
  bottom: 0;
}

.dynamicScreen {
  img.bottom_frame_img {
    position: relative !important;
    z-index: 0 !important;
  }
}

.footer_frame_block {
  display: flex;
}

@media all and (min-width: 1025px) and (max-width: 1140px) {
  .main_body_section {
    width: 86%;
  }

  img.top_frame_img {
    height: 60px;
  }

  .frame_header_block:before,
  .frame_header_block:after {
    background-size: 69% 882px;
    width: 10%;
  }

  .frame_header_block:before {
    background-position: right top;
  }

  .frame_header_block:after {
    background-position: left top;
  }

  li.li_nav_menu_name {
    width: 110px;
    padding: 7px 5px;
    margin: 0 2px;
  }

  li.li_nav_menu_name a {
    font-size: 12px;
  }

  .top_header_nav_column.top_header_col {
    width: 75%;
    padding: 0px 10px;
  }

  .top_header_logo_block img {
    width: auto;
    height: 20px;
  }

  .top_header_logo_column.top_header_col {
    width: 6%;
  }

  .top_header_btn_column.top_header_col {
    width: 5%;
  }

  img.left_icon_img {
    width: auto;
    height: 16px;
  }

  .left_btn_icon_block:after {
    background-size: auto 50px;
  }

  /* BOTTOM SECTION */
  img.bottom_frame_img {
    height: 80px;
    bottom: 0;
  }
}

@media all and (min-width: 1141px) and (max-width: 1280px) {
  .inner_body_container {
    min-height: 75vh;
  }

  .main_body_section {
    width: 86%;
  }

  img.top_frame_img {
    height: 75px;
  }

  .frame_header_block:before,
  .frame_header_block:after {
    background-size: 69% 882px;
    width: 10%;
  }

  .frame_header_block:before {
    background-position: right top;
  }

  .frame_header_block:after {
    background-position: left top;
  }

  li.li_nav_menu_name {
    width: 110px;
    padding: 9px 5px;
  }

  li.li_nav_menu_name a {
    font-size: 13px;
  }

  .top_header_nav_column.top_header_col {
    width: 72%;
    padding: 5px 10px;
  }

  .top_header_logo_block img {
    width: auto;
    height: 25px;
  }

  .top_header_logo_column.top_header_col {
    width: 7%;
  }

  .top_header_btn_column.top_header_col {
    width: 5%;
  }

  /* BOTTOM SECTION */
  img.bottom_frame_img {
    height: 80px;
    bottom: 0;
  }
}

@media all and (min-width: 1281px) and (max-width: 1440px) {
  .inner_body_container {
    min-height: 75vh;
  }

  img.top_frame_img {
    height: 80px;
  }

  .frame_header_block:before,
  .frame_header_block:after {
    background-size: 70% 882px;
    width: 10%;
  }

  .frame_header_block:before {
    background-position: right top;
  }

  .frame_header_block:after {
    background-position: left top;
  }

  li.li_nav_menu_name {
    width: 125px;
    padding: 12px 8px;
  }

  li.li_nav_menu_name a {
    font-size: 15px;
  }

  .top_header_nav_column.top_header_col {
    width: 78%;
    padding: 5px 10px;
  }

  .top_header_logo_block img {
    width: auto;
    height: 30px;
  }

  .top_header_logo_column.top_header_col {
    width: 10%;
  }

  /* BOTTOM SECTION */
  img.bottom_frame_img {
    height: 85px;
    bottom: 0;
  }
}

@media all and (min-width: 1441px) and (max-width: 1680px) {
  .inner_body_container {
    min-height: 75vh;
  }

  img.top_frame_img {
    height: 90px;
  }

  .frame_header_block:before,
  .frame_header_block:after {
    background-size: 78% 882px;
    width: 9%;
  }

  .frame_header_block:before {
    background-position: right top;
  }

  .frame_header_block:after {
    background-position: left top;
  }

  li.li_nav_menu_name {
    width: 130px;
    padding: 16px 12px;
  }

  .top_header_nav_column.top_header_col {
    width: 76%;
    padding: 10px 20px;
  }

  .top_header_logo_block img {
    width: auto;
    height: 35px;
  }

  /* BOTTOM SECTION */
  img.bottom_frame_img {
    height: 119px;
    bottom: 0;
  }
}

@media all and (min-width: 1681px) and (max-width: 1880px) {
  img.top_frame_img {
    height: 115px;
  }

  .frame_header_block:before,
  .frame_header_block:after {
    background-size: 87% 883px;
    width: 8%;
  }

  .frame_header_block:before {
    background-position: right top;
  }

  .frame_header_block:after {
    background-position: left top;
  }

  li.li_nav_menu_name {
    width: 130px;
  }

  .top_header_nav_column.top_header_col {
    width: 67%;
  }

  /* BOTTOM SECTION */
  img.bottom_frame_img {
    height: 100px;
    bottom: 0;
    z-index: -1;
  }
}

@media all and (min-width: 200px) and (max-width: 1025px) {
  .frame_header_row {
    display: none !important;
  }

  .footer_frame_section {
    display: none !important;
  }

  .main_body_section {
    width: 100% !important;
  }

  .inner_body_container {
    // overflow-x: hidden;
  }
}