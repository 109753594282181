@import "./Styles/Color.scss";
@import "./Styles/responsive.scss";
@import "./Styles/Variable.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#___reactour > div:first-child {
  opacity: 0.7;
}
#root{
  overflow-x: hidden!important;
}

.Dashboard_Main_Container {
  background-color: #e8e9ef;
  width: 100vw;
  font-family: Rubik-Medium;
  .Dashboard_Content_Container {
    display: flex;
    padding-top: 10px;
  }
}

.Edu_Dashboard_Content {
  width: 100%;
  padding: 6px 25px 35px 25px;
  justify-content: space-between;
  align-items: flex-start;
  overflow: auto;
}

.reactour__helper {
  max-width: fit-content !important;
}

.completeTour {
  color: white;
  font-weight: bold;
  background: #34c4e1;
  padding: 10px 15px;
  border-radius: 20px;
  position: relative;
  display: flex;
  text-decoration: none;
  margin-top: 5px;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

// DATE_PIKKER

.react-datepicker__header {
  background-color: #fff !important;
  padding: 10px 10px 10px 15px !important;
}

.react-datepicker--time-only {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.158) !important;
}

.react-datepicker__year--container {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.158) !important;
}

.react-datepicker__time-list-item {
}

.react-datepicker__time-list-item--selected {
  background-color: #a1e3f6 !important;
}

.react-datepicker-year-header {
  background-color: #fff !important;
  text-align: left !important;
  padding: 10px 10px 10px 15px !important;
}

.react-datepicker__year .react-datepicker__year-text {
  margin: 13px !important;
  padding: 15px;
}

.react-datepicker__year-text--selected {
  background-color: #92e7ff !important;
}

.react-datepicker__year-text--keyboard-selected {
  background-color: #92e7ff !important;
}

.Year-Colander-Design {
  .react-datepicker__navigation--previous {
    top: 5px !important;
    right: 50px !important;
    left: initial !important;
  }

  .react-datepicker__navigation--next {
    top: 5px !important;
  }

  .react-datepicker__triangle {
    display: none;
  }
}

.react-datepicker__tab-loop {
  .react-datepicker-popper {
    // position: absolute !important;
    // inset: 0px auto 0px 122px !important;
    // transform: translate(0px, -7px) !important;

    .react-datepicker {
      background-color: #fff;
      border: 0px;

      .react-datepicker__navigation-icon {
        &::before {
          border-color: #000;
          height: 5px;
          width: 5px;
          border-width: 2px 2px 0px 0px;
        }
      }

      .react-datepicker__navigation--previous {
        top: 13px;
        right: 50px;
        left: initial;
      }

      .react-datepicker__navigation--next {
        top: 13px;
      }

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__day--selected {
        background-color: #92e7ff !important;
      }

      .react-datepicker__month-container {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.158);

        .react-datepicker__header {
          border-bottom: 0px;
          background-color: #fff;

          .react-datepicker__current-month {
            text-align: left;
            padding: 10px 10px 10px 15px;
          }
        }

        .react-datepicker__month {
          .react-datepicker__week {
            .react-datepicker__day {
              border: 1px solid #fff;

              &:hover {
                border-radius: 50%;
                // background-color: #fff;
                border: 1px solid rgb(236, 236, 236);
                background-color: rgb(236, 236, 236);
              }
            }

            .react-datepicker__day--selected {
              background-color: #92e7ff !important;
              border: 1px solid #92e7ff !important;
              border-radius: 50%;
            }

            .react-datepicker__day--keyboard-selected {
              background-color: #92e7ff !important;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

// DATE_PIKKER_END

.Color {
  color: $primary_Orange;
  // @extend %Flex_Box;
  // @include translateX(5px);
  @extend %Header_Font;
}

.Main_Conatiner {
  background-color: $primary_Light_Blue;
  @extend %Flex_Box;
  @include border-radius(50px);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.invalid-feedback {
  color: #dc3545;
  padding-left: 11px;
  font-size: 15px;
  text-transform: capitalize;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.SavedClass_Container {
  width: 100%;
  // padding: 30px 50px;
  display: flex !important;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}

.rbc-today {
  background-color: transparent !important;
}

.lendo-page-alignment {
  .cus-container {
    // max-width: 1598px;
    // margin: 0 auto;
    padding: 0 0px;
  }
}

.landing-footer-alignment {
  .cus-container {
    padding: 0 20px;
  }
}

.common-header-top-alignment {
  padding-top: 130px;
  padding-bottom: 60px;
  background-color: #f1f2f7;
  min-height: 100vh;
}

.step-edu-icon-alignment {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 40px;

  .active-background {
    background-color: #88dcf4;
    margin-left: 1rem;
  }

  .disabled-background {
    background-color: #d1d1d1;

    img {
      transform: rotate(179deg);
    }
  }

  .icon-design {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 25px;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .parent_child_signup_banner
    .container_fluid
    .signup_grid
    .signup_grid_items
    .form_grid
    .signup_box {
    .sign_up_content,
    .signup_content {
      .invalid-feedback {
        padding-left: 0;
        font-size: 14px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 980px) {
  
}

@media screen and (min-width: 981px) and (max-width: 1024px) {
  
}

@media screen and (min-width: 1025px) and (max-width: 1140px) {
  
}

@media screen and (min-width: 1141px) and (max-width: 1280px) {
  .Dashboard_Main_Container {
    min-height: 96vh;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
  .Dashboard_Main_Container {
    min-height: 96vh;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1680px) {
  .Dashboard_Main_Container {
    min-height: 96vh;
  }
}

@media screen and (min-width: 1681px) and (max-width: 1880px) {
  .Dashboard_Main_Container {
    min-height: 96vh !important;
  }
}
