@import "../../../../Styles/Color.scss";
@import "../../../../Styles/Variable";
@import "../../../../Styles/typography.scss";

.edu-dashboard-banner {
  background-color: #eaecf1;
}

.no-notification-section {
  padding: 15px 10px;
  display: flex;
  margin: auto;
  justify-content: center;
}

header {
  background-color: $primary_White;
  height: 100px;
  padding: 0 80px;
  display: flex;
  position: fixed;
  z-index: 999;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 0;

  .header-alignment,
  .responsive-header-alignment {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .logo {
      img {
        cursor: pointer;
      }
    }

    .menu {
      display: flex;
      align-items: center;

      .user-information-alignment {
        display: flex;
        padding-left: 50px;
        align-items: center;

        .round-design:last-child {
          margin-right: 0px;
        }

        .round-design {
          width: 60px;
          margin-right: 20px;
          cursor: pointer;
          border-radius: 50%;
          height: 60px;
          background-color: #fff4df;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            transition: 0.5s !important;
          }

          &:hover img {
            transform: scale(1.2);
          }
        }

        .notification-section {
          position: relative;

          .notification-count {
            position: absolute;
            right: -5px;
            top: -5px;
            padding: 4px 5px;
            background: #eeb75a;
            border-radius: 50%;
            height: 25px;
            // width: 25px;
            min-width: 25px;
            text-align: center;
            color: white;
            font-size: 14px;
          }
        }

        .Navbar_Single_Icon_DropDown {
          display: flex;
          position: relative;
          background-color: #fff5e3;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          cursor: pointer;
          margin-right: 20px;

          .dropdown_Main_Container {
            position: absolute;
            top: 120%;
            left: 0;
            z-index: 2;
            box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%);
            background-color: #efb959;
            border-radius: 10px;
            width: 160px;

            .how-it-works {
              width: auto;
              height: 20px;
            }

            div {
              padding: 10px 15px;
              color: #ffffff;
              display: flex;
              align-items: center;

              img {
                margin-right: 10px;
              }

              &:hover {
                background-color: rgba(177, 177, 177, 0.267);
                cursor: pointer;
                border-radius: 10px;
              }
            }
          }

          .Profile {
            width: 55px;
            height: 55px;
            border-radius: 50px;
            border: 3px solid #f2cb72;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            img {
              width: 55px;
              height: 55px;
              object-fit: fill;
            }
          }

          .DropDownIconContainer {
            padding: 10px 27px 10px 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0px 60px 60px 0px;

            .DropDownIcon {
              width: 15px;
            }
          }
        }
      }

      ul {
        display: flex;
        align-items: center;
        list-style: none;

        a {
          border-right: 3px solid #9e9e9e;
          text-decoration: none;
          padding: 0 35px;
        }

        .payment_history {
          border: none;
        }

        li {
          @include text-md;
          padding: 0 35px;
          cursor: pointer;
          color: #9e9e9e;
          border-right: 3px solid #9e9e9e;
        }

        li:first-child {
          padding-left: 0px;
        }

        li:last-child {
          border-right: 0px;
          padding-right: 0px;
        }
      }
    }
  }
}

.notification-wrapper {
  width: 20%;
  position: absolute;
  right: 2%;
  top: 12vh;
  padding: 15px 2px 2px 2px;
  background: #fff;
  // max-height: 460px;
  overflow-y: hidden;
  overflow-x: hidden;

  .notification-section {
    .fa.fa-spinner.fa-spin {
      display: flex;
      justify-content: center;
      font-size: 20px;
    }

    .mark-view-all-section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 5px 0px 10px 0px;

      .mark-all-as-read {
        text-align: left;
        font-family: Rubik-Medium !important;
        margin: 0 10px;
        font-size: 16px;
        color: #51bbce;
        text-decoration: underline;
        cursor: pointer;
      }

      .view-all {
        text-align: right;
        font-family: Rubik-Medium !important;
        margin: 0 10px;
        font-size: 16px;
        color: #51bbce;
        cursor: pointer;
      }
    }

    .notification {
      cursor: pointer;
      align-items: center;
      border-bottom: 1px solid $Primary_Font_Color;
      padding: 15px 0;

      .notification-txt {
        display: flex;
        padding: 0 15px;

        .notificatim-img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        p {
          font-family: Rubik-Medium !important;
          margin: 0 10px;
          font-size: 14px;
          color: $Primary_Font_Color;
        }
      }

      &:last-of-type {
        border-bottom: none;
      }

      .notification-date {
        font-family: Rubik-Medium !important;
        margin: 10px 10px 0px 10px;
        font-size: 13px;
        color: $Primary_Font_Color;
        text-align: right;
      }
    }
  }
}

.notification-wrapper::-webkit-scrollbar {
  scrollbar-width: 0;
}

@media screen and (max-width: 1024px) {
  header {
    padding: 0 20px;
    max-width: 100%;
  }

  .header-alignment .logo img {
    width: 110px;
  }

  header .header-alignment .menu ul li {
    font-size: 14px;
    padding: 0 20px;
  }

  header .header-alignment .menu .user-information-alignment .round-design,
  header .header-alignment .menu .user-information-alignment .Navbar_Single_Icon_DropDown .Profile {
    width: 50px;
    height: 50px;
  }

  header .header-alignment .menu .user-information-alignment .Navbar_Single_Icon_DropDown .Profile img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
}

@media screen and (max-width: 767px) {
  .user-information-alignment {
    width: 81px;
    padding-left: 0 !important;

    .Navbar_Single_Icon_DropDown {
      .dropdown_Main_Container {
        width: 100px;
      }

      .DropDownIconContainer {
        padding: 10px 13px 10px 21px !important;
      }

      .Profile {
        width: 43px !important;
        height: 43px !important;

        img {
          width: 43px !important;
          height: 43px !important;
        }
      }
    }
  }

  header {
    height: 75px;
  }

  .user-information-alignment {
    .responsive {
      display: none !important;
    }
  }
}

@media screen and (max-width: 980px) {
  header {
    height: 69px;
    padding: 0 0px 0px 29px;

    .header-alignment {
      width: 96%;
    }
  }

  .user-information-alignment {
    display: flex;
    padding-left: 50px;
    align-items: center;

    .Navbar_Single_Icon_DropDown {
      display: flex;
      position: relative;
      background-color: #fff5e3;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      cursor: pointer;

      .dropdown_Main_Container {
        position: absolute;
        top: 120%;
        left: 0;
        z-index: 2;
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
        background-color: #efb959;
        border-radius: 10px;

        div {
          padding: 10px 15px;
          color: #ffffff;
          display: flex;
          align-items: center;
          font-size: 14px;

          img {
            margin-right: 10px;
            width: 15px;
          }

          &:hover {
            background-color: rgba(177, 177, 177, 0.267);
            cursor: pointer;
            border-radius: 10px;
          }
        }
      }

      .Profile {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        border: 3px solid #f2cb72;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        img {
          width: 50px;
          height: 50px;
          object-fit: fill;
        }
      }

      .DropDownIconContainer {
        padding: 10px 27px 10px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 60px 60px 0px;

        .DropDownIcon {
          width: 15px;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  header {
    height: 69px;
    padding: 0 2% 0% 2%;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .edu-dashboard-banner {
    // height: 116vw !important;
  }

  header {
    height: 69px;
    padding: 0 1% 0% 2%;
  }
}

@media screen and (min-width: 768px) and (max-width: 980px) {
  .edu-dashboard-banner {
    // height: 116vw !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 980px) {
  .edu-dashboard-banner {}

  header .header-alignment .menu ul li {
    padding: 0 !important;
    color: $primary_White;
    font-size: 19px;
  }

  .user-information-alignment {
    .responsive {
      display: none !important;
    }
  }

  header {
    .header-alignment {
      .menu {
        .user-information-alignment {
          padding-left: 0px;
        }

        ul {
          display: block;
          padding-left: 29px;

          a {
            border: none;

            li {
              padding: 0 !important;
              color: $primary_White  !important;
              font-size: 19px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 981px) and (max-width: 1024px) {
  .edu-dashboard-banner {
  }

  header {
    .header-alignment {
      .menu {
        .user-information-alignment {
          padding-left: 0px !important;

          .round-design {
            .chat {
              width: 28px;
            }
          }
        }

        ul {
          li {
            font-size: 17px;
            padding: 0 20px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1140px) {
  .edu-dashboard-banner {
  }

  header .header-alignment .menu ul a {
    padding: 0 25px;
  }

  header {
    padding: 0 26px;

    .header-alignment {
      .menu {
        ul {
          li {
            font-size: 16px;
            padding: 0px 15px;

            a {
              padding: 0 25px;
            }
          }
        }

        .user-information-alignment {
          padding-left: 16px;
        }
      }
    }
  }
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
  .edu-dashboard-banner {
  }

  header {
    .header-alignment {
      .menu {
        ul {
          a {
            padding: 0 17px !important;
          }

          li {
            padding: 0 17px !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1141px) and (max-width: 1280px) {
  .edu-dashboard-banner {
  }

  header {
    .header-alignment {
      .menu {
        ul {
          li {
            font-size: 17px;
            padding: 0px 19px;
          }
        }
      }
    }
  }

  header .header-alignment .menu ul a,
  header .responsive-header-alignment .menu ul a {
    padding: 0 25px;
  }

  header {
    padding: 0 56px;

    .header-alignment {
      .menu ul li {
        font-size: 17px;
      }
    }
  }
}

.nav-menu-educator {
  width: 420px;
  background-color: $primaryblue;
}

.nav-menu-educator {
  background-color: $primaryblue  !important;

  .navbar-toggle {
    background-color: $primaryblue  !important;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1680px) {
  .edu-dashboard-banner {
  }
}

@media screen and (min-width: 1681px) and (max-width: 1880px) {
  .edu-dashboard-banner {
  }
}