@import "./Color.scss";
$Border_radius: 30px;
$boxShadow: 0px 2px 14px -3px #88888859;
@mixin border-radius($radius) {
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin box-shadow( $x-axis: 0, $y-axis: 0px, $blur: 0px, $radius: 0px, $color: $default) {
    box-shadow: $x-axis $y-axis $blur $radius $color;
    -moz-box-shadow: $x-axis $y-axis $blur $radius $color;
    -webkit-box-shadow: $x-axis $y-axis $blur $radius $color;
    -ms-box-shadow: $x-axis $y-axis $blur $radius $color;
}

%Flex_Box {
    display: flex;
    align-items: center;
    justify-content: center;
}

%Flex_Column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

%Flex_Space_Between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

%Header_Font {
    font-family: "GothamRounded-Bold";
    letter-spacing: 0.3px;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    line-height: 1.2;
}

%NavBar_Font {
    font-family: "GothamRounded-Medium";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.3px;
}

%Border {
    border: 3px solid $primary_White;
}

%Absolute9999 {
    position: absolute;
    z-index: 9999;
}

%Absolute999 {
    position: absolute;
    z-index: 999;
}

%Common_Font_Size {
    font-family: "GothamRounded-Bold" !important;
    letter-spacing: 0.3px !important;
    text-decoration: none !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    text-decoration: none !important;
    @media screen and (max-width: 1800px) {
        font-family: "GothamRounded-Bold" !important;
        letter-spacing: 0.3px !important;
        text-decoration: none !important;
        line-height: 1.2 !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        text-decoration: none !important;
    }
    @media screen and (max-width: 1600px) {
        font-family: "GothamRounded-Bold" !important;
        letter-spacing: 0.3px !important;
        text-decoration: none !important;
        line-height: 1.2 !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        text-decoration: none !important;
    }
    @media screen and (max-width: 1500px) {
        font-family: "GothamRounded-Bold" !important;
        letter-spacing: 0.3px !important;
        text-decoration: none !important;
        line-height: 1.2 !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        text-decoration: none !important;
    }
    @media screen and (max-width: 1300px) {
        font-family: "GothamRounded-Bold" !important;
        letter-spacing: 0.3px !important;
        text-decoration: none !important;
        line-height: 1.2 !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        text-decoration: none !important;
    }
    @media screen and (max-width: 1200px) {
        font-family: "GothamRounded-Bold" !important;
        letter-spacing: 0.3px !important;
        text-decoration: none !important;
        line-height: 1.2 !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        text-decoration: none !important;
    }
    @media screen and (max-width: 1100px) {
        font-family: "GothamRounded-Bold" !important;
        letter-spacing: 0.3px !important;
        text-decoration: none !important;
        line-height: 1.2 !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        text-decoration: none !important;
    }
    @media screen and (max-width: 900px) {
        font-family: "GothamRounded-Bold" !important;
        letter-spacing: 0.3px !important;
        text-decoration: none !important;
        line-height: 1.2 !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        text-decoration: none !important;
    }
    @media screen and (max-width: 600px) {
        font-family: "GothamRounded-Bold" !important;
        letter-spacing: 0.3px !important;
        text-decoration: none !important;
        line-height: 1.2 !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        text-decoration: none !important;
    }
    @media screen and (max-width: 300px) {
        font-family: "GothamRounded-Bold" !important;
        letter-spacing: 0.3px !important;
        text-decoration: none !important;
        line-height: 1.2 !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        text-decoration: none !important;
    }
}

%Common_Regular_Font {
    font-family: "GothamRounded-Medium" !important;
    letter-spacing: 0.3px !important;
    font-weight: 600 !important;
    line-height: 1.2 !important;
    font-size: 12px !important;
    text-decoration: none !important;
    @media screen and (max-width: 1500px) {
        font-family: "GothamRounded-Medium" !important;
        letter-spacing: 0.3px !important;
        font-weight: 600 !important;
        line-height: 1.2 !important;
        font-size: 12px !important;
        text-decoration: none !important;
    }
}

%Common_15_Regular_Font {
    font-family: "GothamRounded-Medium" !important;
    letter-spacing: 1.3px !important;
    font-weight: unset !important;
    line-height: 1.2 !important;
    font-size: 12px !important;
    text-decoration: none !important;
    @media screen and (max-width: 1600px) {
        font-family: "GothamRounded-Medium" !important;
        letter-spacing: 0.3px !important;
        font-weight: bold !important;
        line-height: 1.2 !important;
        font-size: 14px !important;
        text-decoration: none !important;
    }
    @media screen and (max-width: 1500px) {
        font-family: "GothamRounded-Medium" !important;
        letter-spacing: 0.3px !important;
        font-weight: bold !important;
        line-height: 0.8 !important;
        font-size: 12px !important;
        text-decoration: none !important;
    }
}

%Common_20_Regular_Font {
    font-family: "GothamRounded-Medium" !important;
    font-weight: bold !important;
    font-size: 21px !important;
    text-decoration: none !important;
    letter-spacing: 0.9px;
    @media screen and (max-width: 1600px) {
        font-family: "GothamRounded-Medium" !important;
        font-weight: bold !important;
        font-size: 19px !important;
        text-decoration: none !important;
        letter-spacing: 0.9px;
    }
    @media screen and (max-width: 1500px) {
        font-family: "GothamRounded-Medium" !important;
        font-weight: bold !important;
        text-decoration: none !important;
        letter-spacing: 0.9px;
    }
}

%Common_Educator_15_Regular_Font {
    color: #c2c2c2;
    font-size: 17px !important;
    font-weight: normal !important;
    text-decoration: none !important;
    font-family: Rubik-Medium !important;
   
    @media screen and (max-width: 1600px) {
        color: #c2c2c2;
        font-size: 15px !important;
        font-weight: normal !important;
        text-decoration: none !important;
        font-family: Rubik-Medium !important;
    }
}