@import "../../../../Styles/Color.scss";

.parent_footer_container {
  display: none;

  .footer_content {
    background-color: $primaryblue;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 65px;
    z-index: 1;

    .user-information-alignment {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 5px 0px;

      .round-design:last-child {
        margin-right: 0px;
      }

      .round-design {
        width: 50px;
        height: 50px;
        margin-right: 20px;
        cursor: pointer;
        border-radius: 50%;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;

        .chat {
          width: 28px;
        }

        .notification {
          width: 24px;
        }
      }

      .notification-section {
        position: relative;

        .notification-count {
          position: absolute;
          right: -10px;
          top: -10px;
          padding: 4px 5px;
          background: #eeb75a;
          border-radius: 50%;
          height: 25px;
          // width: 25px;
          text-align: center;
          color: white;
          font-size: 14px;
        }
      }

      .Navbar_Single_Icon_DropDown {
        display: flex;
        position: relative;
        background-color: #fff5e3;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        cursor: pointer;
        margin-right: 20px;

        .dropdown_Main_Container {
          position: absolute;
          top: 120%;
          left: 0;
          z-index: 2;
          box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
          background-color: #efb959;
          border-radius: 10px;

          div {
            padding: 10px 15px;
            color: #ffffff;
            display: flex;
            align-items: center;

            img {
              margin-right: 10px;
            }

            &:hover {
              background-color: rgba(177, 177, 177, 0.267);
              cursor: pointer;
              border-radius: 10px;
            }
          }
        }

        .Profile {
          width: 50px;
          height: 50px;
          border-radius: 50px;
          border: 3px solid #f2cb72;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          img {
            width: 55px;
            height: 55px;
            object-fit: fill;
          }
        }

        .DropDownIconContainer {
          padding: 10px 27px 10px 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px 60px 60px 0px;

          .DropDownIcon {
            width: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .parent_footer_container {
    display: block !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .parent_footer_container {
    .footer_content {
      .user-information-alignment {
        padding: 11px 0px;
        width: 100% !important;

        .round-design {
          width: 39px;
          height: 39px;

          .chat {
            width: 25px;
          }

          .notification {
            width: 21px;
          }

          .question {
            width: 16px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 980px) {
  .parent_footer_container {
    .footer_content {
      .user-information-alignment {
        .round-design {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
