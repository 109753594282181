.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 999999;
    background-color: #85daeecc;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup_inner {
    position: absolute;
    margin: auto;
    background: rgb(255, 255, 255);
    border-radius: 30px;
    padding: 30px 35px;
    align-items: center;
    // display: grid;
    // display: inherit;
    justify-content: center;
}

.Popup_Close_Btn {
    position: absolute;
    right: 35px;
    top: 35px;
    width: 25px;
    height: 25px;
    z-index: 1;
}

.popup_Title {
    font-size: 42px;
    font-weight: bold;
    color: #7ed9f6;
    // padding-bottom: 65px;
    text-align: center;
    padding-bottom: 20px;
    // position: absolute;
}

// .popup_Title {
//     font-size: 35px;
//     font-weight: bold;
//     color: #5c5c5c;
// }
.Popup_SubText {
    font-size: 20px;
    color: #afafafe7;
    margin-top: 25px;
}

.Popup_Input_Container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 25px 0px;
    border-bottom: 2px solid #5c5c5c;
    padding-bottom: 20px;
    .Popup_Input_Left_Container {
        width: 80%;
        div {
            font-size: 20px;
            font-weight: bold;
            color: #5c5c5c;
            margin-bottom: 5px;
        }
        input {
            width: 100%;
            padding: 20px;
            font-size: 17px;
            border-radius: 10px;
            outline: none;
            border: none;
            border: 2px solid #5c5c5c;
        }
    }
    .Popup_Input_Right_Container {
        width: 20%;
        margin-left: 15px;
        #normal-select-2 {
            width: 100%;
            padding: 20px;
            font-size: 17px;
            border-radius: 10px;
            outline: none;
            border: none;
            border: 2px solid #5c5c5c;
        }
    }
}

.Popup_Bottom_Container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    .popup_btn {
        width: 160px;
        height: 60px;
        background-color: #7ED9F6;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 23px;
        color: #fff;
        font-weight: 600;
        border-radius: 15px;
        margin: 0 10px;
        transition: 0.5s !important;
        cursor: pointer;
        &:hover {
            background-color: #FFC65B;
        }
    }
    // .Popup_Bottom_Cancel {
    //     font-size: 20px;
    //     color: #84d8f5;
    //     cursor: pointer;
    //     padding: 15px 30px;
    //     font-size: 20px;
    //     border-radius: 10px;
    //     border: 2px solid #84d8f5;
    // }
    // .Popup_Bottom_Send {
    //     background-color: #84d8f5;
    //     padding: 15px 30px;
    //     color: #fff;
    //     border-radius: 10px;
    //     font-size: 20px;
    //     cursor: pointer;
    //     border: 2px solid #84d8f5;
    // }
}

// @media (min-width: 600px) and (max-width: 1020px) {
//     .popup_inner {
//         width: 80%;
//     }
//     .Popup_Close_Btn {
//         width: 20px;
//         height: 20px;
//     }
//     // .popup_Title {
//     //     font-size: 25px;
//     //     font-weight: bold;
//     //     color: #5c5c5c;
//     // }
//     .Popup_SubText {
//         font-size: 17px;
//         color: #afafafe7;
//         margin-top: 10px;
//     }
//     .Popup_Input_Container {
//         margin: 15px 0px;
//         padding-bottom: 15px;
//         .Popup_Input_Left_Container {
//             width: 70%;
//             div {
//                 font-size: 17px;
//             }
//             input {
//                 padding: 15px;
//                 font-size: 15px;
//                 border-radius: 10px;
//             }
//         }
//         .Popup_Input_Right_Container {
//             width: 30%;
//             margin-left: 15px;
//             #normal-select-2 {
//                 width: 100%;
//                 padding: 15px;
//                 font-size: 15px;
//             }
//         }
//     }
//     .Popup_Bottom_Container {
//         .Popup_Bottom_Cancel {
//             font-size: 17px;
//         }
//         .Popup_Bottom_Send {
//             font-size: 17px;
//         }
//     }
// }
@media (min-width: 250px) and (max-width: 600px) {
    // .popup_inner {
    //     width: 90%;
    // }
    .Popup_Close_Btn {
        width: 15px;
        height: 15px;
    }
    // .popup_Title {
    //     font-size: 20px;
    //     font-weight: bold;
    //     color: #5c5c5c;
    // }
    .Popup_SubText {
        font-size: 15px;
        color: #afafafe7;
        margin-top: 10px;
    }
    .Popup_Input_Container {
        margin: 15px 0px;
        padding-bottom: 15px;
        .Popup_Input_Left_Container {
            width: 60%;
            div {
                font-size: 15px;
            }
            input {
                padding: 15px;
                font-size: 15px;
                border-radius: 10px;
            }
        }
        .Popup_Input_Right_Container {
            width: 40%;
            margin-left: 10px;
            #normal-select-2 {
                padding: 15px;
                font-size: 15px;
            }
        }
    }
    .Popup_Bottom_Container {
        .Popup_Bottom_Cancel {
            font-size: 15px;
        }
        .Popup_Bottom_Send {
            font-size: 15px;
        }
    }
}

@media (min-width: 300px) and (max-width: 480px) {
    .css-soxuei-IndicatorsContainer {
        height: 36px !important;
    }

.popup{
    .popup_inner {
        padding: 20px;
    }
    .Popup_Bottom_Container {
        // display: block;
        margin: auto;
        .popup_btn {
            margin: 10px 0;
            height: 50px;
            width: 130px;
        }
    }
    .popup_Title {
        font-size: 20px;
    }
}
}

@media (min-width: 481px) and (max-width: 767px) {
 
.popup{
  
    .Popup_Bottom_Container {
        .popup_btn {
            margin: 10px 0;
            height: 50px;
            font-size: 16px;
        }
    }
    .popup_Title {
        font-size: 30px;
    }
}
}

@media (min-width: 768px) and (max-width: 980px) {
    // .Dashboard_Main_Container{
    .popup {
     
        .popup_inner {
            width: 600px !important;
            .popup_Title {
                font-size: 31px !important;
            }
            .Popup_Bottom_Container {
                .popup_btn {
                    font-size: 18px;
                    width: 150px;
                    height: 45px;
                }
            }
        }
    }
}

// }
@media (min-width: 981px) and (max-width: 1025px) {
    // .Dashboard_Main_Container{
    .popup {
     
        .popup_inner {
            .popup_Title {
                font-size: 32px;
            }
            .Popup_Bottom_Container {
                .popup_btn {
                    font-size: 16px;
                    width: 150px;
                    height: 45px;
                }
            }
        }
    }
}

// }
@media (min-width: 1026px) and (max-width: 1140px) {
    // .Dashboard_Main_Container{

 


    .popup {
     
      
        .popup_inner {
            .popup_Title {
                font-size: 34px;
            }
            .Popup_Bottom_Container {
                .popup_btn {
                    font-size: 18px;
                    width: 150px;
                    height: 50px;
                }
            }
        }
    }
    // }  
}

@media (min-width: 1141px) and (max-width: 1280px) {
    // .Dashboard_Main_Container{

      

    .popup {
        .popup_inner {
            // height: 230px !important;
            .popup_Title {
                font-size: 36px;
            }
            .Popup_Bottom_Container {
                .popup_btn {
                    font-size: 22px;
                    width: 150px;
                }
            }
        }
    }
    // }
    // .popup {
    //     .popup_inner {
    //         width: 735px !important;
    //         // height: 230px !important;
    //         .popup_Title {
    //             font-size: 38px;
    //         }
    //         .Popup_Bottom_Container {
    //             .popup_btn {
    //                 font-size: 22px;
    //                 width: 150px;
    //             }
    //         }
    //     }
    // }
}

// }

@media (min-width: 1281px) and (max-width: 1440px) {
    


}


@media (min-width: 1441px) and (max-width: 1680px) {
  
   

}

@media (min-width: 1681px) and (max-width: 1880px) {
   
}

