.a-routeFadeIn {
    // animation: a-routeFadeIn 0.75s ease-in 0.25s;
    -webkit-animation: slideIn 2s forwards;
    -moz-animation: slideIn 2s forwards;
    animation: slideIn 0.9s forwards;
  }
  @-webkit-keyframes slideIn {
    0% {
      transform: translateX(900px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 200ms;
    }
  }
  @-moz-keyframes slideIn {
    0% {
      transform: translateX(900px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 200ms;
    }
  }
  @keyframes slideIn {
    0% {
      transform: translateX(900px);
      opacity: 0;
      filter:blur(8px);
      -o-filter:blur(8px);
      -ms-filter:blur(8px);
      -moz-filter:blur(8px);
      -webkit-filter:blur(8px);
    }
    100% {
      transform: translateX(0);
      opacity: 200ms;
      filter:blur(0px);
      -o-filter:blur(0px);
      -ms-filter:blur(0px);
      -moz-filter:blur(0px);
      -webkit-filter:blur(0px);
    }
  }

  @keyframes a-routeFadeIn {
    0% {
      opacity: 0;
      transform: translateY(30px);
      height: 0;
      overflow: hidden;
    }
    0.01% {
      height: auto;
      overflow: visible;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
 
  .a-routeFadeIn.fade-exit {
    animation: none;
    animation-fill-mode: none;
    opacity: 1;
    transition: opacity 2s ease-in-out;
  }
 
  .a-routeFadeIn.fade-exit-active {
    opacity: 0;
  }

  

  // fase out animation classes

  .a-routeFadeOut {
    // animation: a-routeFadeIn 0.75s ease-in 0.25s;
    -webkit-animation: slideOut 2s forwards;
    -moz-animation: slideOut 2s forwards;
    animation: slideOut 0.9s forwards;
  }
  @-webkit-keyframes slideOut {
    0% {
      transform: translateX(900px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 200ms;
    }
  }
  @-moz-keyframes slideOut {
    0% {
      transform: translateX(900px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 200ms;
    }
  }
  @keyframes slideOut {
    0% {
      transform: translateX(-900px);
      opacity: 0;
      filter:blur(8px);
      -o-filter:blur(8px);
      -ms-filter:blur(8px);
      -moz-filter:blur(8px);
      -webkit-filter:blur(8px);
    }
    100% {
      transform: translateX(0);
      opacity: 200ms;
      filter:blur(0px);
      -o-filter:blur(0px);
      -ms-filter:blur(0px);
      -moz-filter:blur(0px);
      -webkit-filter:blur(0px);
    }
  }

  @keyframes a-routeFadeOut {
    0% {
      opacity: 0;
      transform: translateY(0);
      height: 0;
      overflow: hidden;
    }
    0.01% {
      height: auto;
      overflow: visible;
    }
    100% {
      opacity: 1;
      transform: translateY(30px);
    }
  }
 
  .a-routeFadeOut.fade-exit {
    animation: none;
    animation-fill-mode: none;
    opacity: 1;
    transition: opacity 2s ease-in-out;
  }
 
  .a-routeFadeOut.fade-exit-active {
    opacity: 0;
  }