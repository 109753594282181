// @import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,800;0,900;1,600&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Dosis:wght@800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: GothamRounded-Bold;
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/gotham-rounded/GothamRounded-Bold.otf)
    format("truetype");
}

@font-face {
  font-family: GothamRounded-Medium;
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/gotham-rounded/GothamRounded-Medium.otf)
    format("truetype");
}

@font-face {
  font-family: Rubik-Regular;
  src: url(https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/fonts/Rubik/Rubik-Regular.ttf)
    format("truetype");
}

html,
body {
  margin: 0;
  // height: 100%;
  // overflow: hidden;
}

body {
  font-family: GothamRounded-Bold;
  font-family: GothamRounded-Medium;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  // padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px)
  //   env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px);
}

::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.075);
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.075);
  // border-radius: 10px;
  // background-color: #f5f5f5;
}

::-webkit-scrollbar {
  // width: 10px;
  // background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  // border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.062);
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.062);
  // background-color: #57c9e0a2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// #root {
//   height: 100%;
// }

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

// #root > div {
//   height: 100%;
// }

.Error_Input {
  border: 2px solid red !important;
  color: red !important;
}

.Error_Title {
  color: red !important;
  display: block !important;
  padding-top: 13px !important;
  font-size: 18px !important;
  line-height: 25px !important;
  letter-spacing: 0.9px !important;
  font-weight: 400 !important;
  font-family: Rubik-Medium !important;
}

textarea {
  resize: none;
}
